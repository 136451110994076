// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".images___6SrTL{padding-top:10px}.card___2I_id img{width:auto;overflow:hidden;max-width:100%;margin:10px;cursor:pointer}.card___2I_id img:hover{transition:0.4s ease-out;box-shadow:0px 7px 10px rgba(0,0,0,0.5)}.stotram___1JV-d{margin-left:20px;margin-top:10px;margin-right:auto}.stotram___1JV-d h2{font-size:22px}@media screen and (min-width: 320px) and (max-width: 480px){.card___2I_id img{margin:0;margin-bottom:15px}.stotram___1JV-d{margin-left:0;margin-top:30px}.stotram___1JV-d h2{font-size:22px}}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/subhagadiya-web-app/src/components/MainContent/Images/style.scss"],"names":[],"mappings":"AAAA,gBACC,gBAAgB,CACd,kBAGF,UAAW,CACX,eAAgB,CAChB,cAAe,CACf,WAAY,CACZ,cAAe,CALd,wBAOA,wBAAyB,CACzB,uCAA2C,CAC1C,iBAIC,gBAAiB,CACpB,eAAgB,CACb,iBAAkB,CAHpB,oBAKA,cAAe,CACf,4DAGD,kBACA,QAAS,CACT,kBAAmB,CAClB,iBAED,aAAc,CACd,eAAgB,CAFhB,oBAIC,cAAe,CACf","file":"style.scss","sourcesContent":[".images {\r\n\tpadding-top:10px;\r\n  }\r\n\r\n  .card img {\r\n\twidth: auto;\r\n\toverflow: hidden;\r\n\tmax-width: 100%;\r\n\tmargin: 10px;\r\n\tcursor: pointer;\r\n\t&:hover {\r\n\t\ttransition: 0.4s ease-out;\r\n\t\tbox-shadow: 0px 7px 10px rgba(0 ,0 ,0 , .5);\r\n\t\t}\r\n  }\r\n\r\n  .stotram {\r\n    margin-left: 20px;\r\n\tmargin-top: 10px;\r\n    margin-right: auto;\r\n\th2 {\r\n\t\tfont-size: 22px;\r\n\t}\r\n}\r\n  @media screen  and (min-width: 320px) and (max-width: 480px){\r\n .card img {\r\n\tmargin: 0;\r\n\tmargin-bottom: 15px;\r\n }\r\n .stotram {\r\n\tmargin-left: 0;\r\n\tmargin-top: 30px;\r\n\th2 {\r\n\t\tfont-size: 22px;\r\n\t}\r\n}\r\n  }"]}]);
// Exports
exports.locals = {
	"images": "images___6SrTL",
	"card": "card___2I_id",
	"stotram": "stotram___1JV-d"
};
module.exports = exports;
