import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'
import { MONTHS } from '../../constants'
import { MainHeader, MainContent, MainFooter } from '../../components'
import styles from './style.scss'

class Home extends Component {
  static propTypes = {
    applicationModel: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: '',
  }

  constructor(props) {
    super(props)
    this.state = {
      filterDetails: {},
      selectedMonth: MONTHS[(new Date().getMonth())],
      selectedYear: (new Date().getFullYear()),
      currentURL: '',
      stotraContent: '',
      stotraName: '',
      curRaasi: 'makara-raasi',
      caller: '',
    }
    this.url = window.location.href
  }

  getRenderComponent() {
    const { selectedKey, match } = this.props
    const { filterDetails } = this.state
    const { selectedMonth, caller } = this.state
    const { selectedYear, currentURL, stotraContent, stotraName, curRaasi } = this.state

    switch (selectedKey) {
      default:
        return (
          <MainContent
            {...this.props}
            caller={caller} curRaasi={curRaasi} currentURL={currentURL}
            filter={filterDetails} getContent={this.getContent} gotoURL={this.gotoURL}
            match={match} showMonth={selectedMonth} showRaasi={this.showRaasi}
            showYear={selectedYear} stotraContent={stotraContent} stotraName={stotraName} />
        )
    }
  }

  gotoURL = (url, filterDetails) => {
    const { history } = this.props
    this.setState({
      filterDetails,
      currentURL: url,
    })
    history.push(url)
    window.scrollTo(0, 0)
  }

  openURL = (url) => {
    const { history } = this.props
    history.push(url)
  }

  openCalendar = (selectedMonth) => {
    this.setState({
      selectedMonth,
    })
  }

  selectedYear = (evtKey) => {
    const { selectedMonth } = this.state
    this.openURL(`/calendar/${evtKey}/${selectedMonth}`)
    this.setState({
      selectedYear: evtKey,
    })
  }

  changeMonth = (evtKey) => {
    const { selectedYear } = this.state
    this.openURL(`/calendar/${selectedYear}/${evtKey}`)
    this.setState({
      selectedMonth: evtKey,
    })
  }

  getContent = (name, url, call) => {
    fetch(url).then((r) => {
      r.text().then(d => {
        this.setState({
          stotraContent: d,
          stotraName: name,
          caller: call,
        })
      })
    })
  }

  showRaasi = (curRaasi) => {
    this.setState({
      curRaasi,
    })
    this.openURL(`/raasi-phalaalu/${curRaasi}`)
  }

  render() {
    const { selectedYear, selectedMonth } = this.state

    return (
      <div className={classnames(styles.main)}>
        <MainHeader
          changeMonth={this.changeMonth} gotoURL={this.gotoURL}
          openCalendar={this.openCalendar} openURL={this.openURL}
          selectedYear={this.selectedYear} showMonth={selectedMonth} showYear={selectedYear} />
        { this.getRenderComponent() }
        <MainFooter gotoURL={this.gotoURL} subscribe={this.subscribe} />
      </div>
    )
  }
}

export default withRouter(Home)
