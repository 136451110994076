/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import cs from 'classnames'
import { get, map } from 'lodash'
import { RAASULU } from '../../../constants'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'

class Home extends Component {
static propTypes = {
  getContent: PropTypes.func.isRequired,
  gotoURL: PropTypes.func.isRequired,
  showMonth: PropTypes.string,
  selectedYear: PropTypes.string,
  showYear: PropTypes.string,
  showRaasi: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}
constructor(props) {
  super(props)
  this.state = {
    showFullTestmonial: false,
  }
}

getCalendarFromURL() {
  const { match } = this.props
  const urlYear = get(match, 'params.year', '')
  const urlMonth = get(match, 'params.month', '')

  if (urlYear && urlMonth) {
    return `${urlMonth}-${urlYear}.jpg`
  }
  const { showMonth, showYear } = this.props
  return `${showMonth}-${showYear}.jpg`
}

showFullTestmonial = () => {
  const { showFullTestmonial } = this.state
  this.setState({
    showFullTestmonial: !showFullTestmonial,
  })
}

render() {
  const { gotoURL } = this.props
  const url = this.getCalendarFromURL()
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col lg={3} md={3} xl={3}>
            <div className={cs(st.raasi_phalaalu)}>
              <h2>రాశి ఫలాలు</h2>
              <div className={cs(st.raasulu)}>
                { map(RAASULU, (raasi, key) => (
                  <div className={cs(st.raasi)} onClick={() => this.props.showRaasi(get(raasi, 'content'))}>
                    {get(raasi, 'name')}
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} xl={6}>
            <div className={cs(st.calendar)}>
              <img alt="calendar" src={`/assets/calendar/${url}`} />
            </div>
            <div className={cs(st.calendar_mobile)}>
              <img alt="calendar" src={`/assets/calendar/xs/${url}`} />
            </div>
          </Col>
          <Col lg={2} md={2} xl={2}>
            <div className={cs(st.stotram)}>
              <h2>స్తోత్రములు</h2>
              <RightPanel caller={'calendar'} getContent={this.props.getContent} gotoURL={gotoURL} />
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
}
export default withRouter(Home)
