// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".stotra_panel___ONGLg{color:#212121}.stotra_panel___ONGLg ul li{list-style-type:none}.stotra_panel___ONGLg ul li:hover{color:#e33c43}.stotra_list___3dtuz{display:block}.stotra_list___3dtuz ul li{color:#212121;padding-left:20px;padding:5px 10px}.category___2Okoo{color:#e33c43;font-size:16px;cursor:pointer}.category___2Okoo:hover{font-weight:600}.link___3_tC_ div{cursor:pointer}.link___3_tC_ div:hover{font-weight:600}.title___3JkOc{padding:8px 10px !important}.sidebar___ICwC_{border-left:1px solid #e5e5e5}.sidebar_nav___3P4Nr{margin-bottom:40px}.active___13NLv{border-left:4px solid #f08200}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/subhagadiya-web-app/src/components/common/RightPanel/style.scss"],"names":[],"mappings":"AAAA,sBACC,aAAc,CADf,4BAGE,oBAAqB,CAHvB,kCAKG,aAAc,CACd,qBAIF,aAAc,CADf,2BAGE,aAAc,CACd,iBAAkB,CAClB,gBAAiB,CAEjB,kBAGA,aAAc,CACd,cAAe,CACf,cAAe,CAHhB,wBAKE,eAAgB,CAChB,kBAGF,cAAe,CADhB,wBAGE,eAAgB,CAChB,eAGD,2BAA4B,CAC5B,iBAGG,6BAA8B,CAEjC,qBAEC,kBAAmB,CACpB,gBAEA,6BAA8B","file":"style.scss","sourcesContent":[".stotra_panel {\r\n\tcolor: #212121;\r\n\tul li {\r\n\t\tlist-style-type: none;\r\n\t\t&:hover {\r\n\t\t\tcolor: #e33c43;\r\n\t\t}\r\n\t}\r\n}\r\n.stotra_list {\r\n\tdisplay: block;\r\n\tul li {\r\n\t\tcolor: #212121;\r\n\t\tpadding-left: 20px;\r\n\t\tpadding: 5px 10px;\r\n\r\n\t}\r\n\t}\r\n\t.category {\r\n\t\tcolor: #e33c43;\r\n\t\tfont-size: 16px;\r\n\t\tcursor: pointer;\r\n\t\t&:hover {\r\n\t\t\tfont-weight: 600;\r\n\t\t}\r\n\t}\r\n.link div {\r\n\tcursor: pointer;\r\n\t&:hover {\r\n\t\tfont-weight: 600;\r\n\t}\r\n}\r\n.title {\r\n\tpadding: 8px 10px !important;\r\n}\r\n.sidebar {\r\n //   padding-left: 50px;\r\n    border-left: 1px solid #e5e5e5;\r\n //   margin-left: 50px;\r\n}\r\n.sidebar_nav {\r\n  margin-bottom: 40px;\r\n}\r\n.active {\r\n\tborder-left: 4px solid #f08200;\r\n}\r\n\r\n@media screen  and (min-width: 320px) and (max-width: 480px){\r\n\r\n}"]}]);
// Exports
exports.locals = {
	"stotra_panel": "stotra_panel___ONGLg",
	"stotraPanel": "stotra_panel___ONGLg",
	"stotra_list": "stotra_list___3dtuz",
	"stotraList": "stotra_list___3dtuz",
	"category": "category___2Okoo",
	"link": "link___3_tC_",
	"title": "title___3JkOc",
	"sidebar": "sidebar___ICwC_",
	"sidebar_nav": "sidebar_nav___3P4Nr",
	"sidebarNav": "sidebar_nav___3P4Nr",
	"active": "active___13NLv"
};
module.exports = exports;
