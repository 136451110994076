/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import cs from 'classnames'
import PropTypes from 'prop-types'
import { map, split, get } from 'lodash'
import { STOTRAS } from '../../../constants/stotras'
import { Icon, IconType } from '../../common'
import st from './style.scss'
import RightPanel from '../../common/RightPanel'

export default class Stotras extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
  name: PropTypes.string,
  currentURL: PropTypes.string,
  getContent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  stotraName: PropTypes.string,
  stotraContent: PropTypes.string,
  caller: PropTypes.string,
}

constructor(props) {
  super(props)
  this.state = {
    strotraDisplay: '',
    fontSize: 16,
    category: '',
  }
}

componentDidMount() {
  this.getRenderComponent()
}

getStrotraName = () => {
  map(STOTRAS, (category, key) => (
    map(get(category, 'list'), (strotra, index) => (
      this.isMatched(get(category, 'page'), get(strotra, 'name'), get(strotra, 'display'))
    ))
  ))
}

getRenderComponent() {
  const { match, stotraName } = this.props
  const selectedCategory = get(match, 'params.category', '')
  const selectedStotra = get(match, 'params.name', '')

  if (selectedCategory && !selectedStotra){
    console.log('Category Selected', selectedCategory, selectedStotra)
    this.setState({
      category: selectedCategory,
    })
  }

  if (!selectedCategory && !selectedStotra) {
    return (
      this.props.getContent('గోవింద నామావళి', '/assets/stotras/venkateswara/govinda-naamaavali.txt')
    )
  }
  if (selectedCategory) {
    this.getStrotraName()
    const { strotraDisplay } = this.state
    this.props.getContent(strotraDisplay, `/assets/stotras/${selectedCategory}/${selectedStotra}.txt`)
  }

  return (
    this.props.getContent(stotraName, `/assets/stotras/${selectedCategory}/${selectedStotra}.txt`)
  )
}

changeFontSize = (size) => {
  this.setState({
    fontSize: size,
  })
}

getCategoryStotraList(cat) {
  return (
    <div>
      {map(STOTRAS, (val, key) => (
        this.printStotras(val, cat)
      ))}
    </div>
  )
}

printStotras(val, cat) {
  if (get(val, 'page') === cat) {
    return (
      console.log('List is ', get(val, 'list'))
    )
  }
  return (
    <div>Null {cat}</div>
  )
}

isMatched(cat, name, display) {
  const { match } = this.props
  const selectedCategory = get(match, 'params.category', '')
  const selectedStotra = get(match, 'params.name', '')
  if (cat === selectedCategory && name === selectedStotra){
    this.setState({
      strotraDisplay: display,
    })
  }
}

showStotra(stotraName, strotraDisplay) {
  if (!strotraDisplay && (stotraName.length === 0 || stotraName === 'గోవింద నామావళి')) {
    return 'గోవింద నామావళి'
  }
  const { caller } = this.props
  if (stotraName && caller === 'stotra'){
    return stotraName
  }
  return strotraDisplay
}
render() {
  const { gotoURL, stotraContent } = this.props
  const content = split(stotraContent, '\n')
  const { stotraName } = this.props
  const { strotraDisplay, fontSize, category } = this.state
  const { FONT_1, FONT_2 } = IconType.COMMON
  console.log('Fontsize', fontSize)
  return (
    <Fragment>
      <Container>
        <Row className={cs(st.reverse)}>

          <Col lg={8} md={6} xl={8}>
            { category ? this.getCategoryStotraList(category) : (
              <div>
                <div>
                  {'all'} / {category} / {stotraName}
                </div>
                <div className={cs(st.stotras, fontSize === 16 ? st.small : (fontSize === 18 ? st.medium : st.large))}>
                  <div className={cs(st.font)}>
                    <span>Font Size</span>
                    <div className={cs(st.sm)} onClick={() => this.changeFontSize(16)}>
                      <Icon
                        color={'none'}
                        height={'16'}
                        names={[FONT_1, FONT_2]}
                        viewHeight={'48'}
                        viewWidth={'48'} width={'16'} />
                    </div>
                    <div className={cs(st.md)} onClick={() => this.changeFontSize(18)}>
                      <Icon
                        color={'none'}
                        height={'20'}
                        names={[FONT_1, FONT_2]}
                        viewHeight={'48'}
                        viewWidth={'48'} width={'20'} />
                    </div>
                    <div className={cs(st.lg)} onClick={() => this.changeFontSize(20)}>
                      <Icon
                        color={'none'}
                        height={'24'}
                        names={[FONT_1, FONT_2]}
                        viewHeight={'48'}
                        viewWidth={'48'} width={'24'} />
                    </div>
                  </div>
                  <div className={cs(st.title)}>
                    {this.showStotra(stotraName, strotraDisplay)}
                  </div>
                  <div className={cs(st.stotra_content)}>
                    {map(content, (val, key) => (
                      <div>
                        <div>{val.length === 1 && <br />} </div>
                        <div> { val } </div>
                      </div>
                    ))}
                  </div>
                  <div className={cs(st.end)} />
                </div>
              </div>
            )}
          </Col>
          <Col lg={4} md={4} xl={4}>
            <div className={cs(st.stotram)}>
              <h2>స్తోత్రములు</h2>
              <RightPanel caller={'stotra'} getContent={this.props.getContent} gotoURL={gotoURL} />
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
}
