/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable max-len */

export const STOTRAS = [
  {
    category: 'వినాయక',
    page: 'vinayaka',
    list: [
		   {
			  name: 'sri-gananatham-bhajamyaham',
			  display: 'శ్రీ గణనాథం భజామ్యహం',
			  url: '/assets/stotras/vinayaka/sri-gananatham-bhajamyaham.txt',
		   },
		   {
			  name: 'ganesha-ashtakam',
			  display: 'గణేశ అష్టకం',
			  url: '/assets/stotras/vinayaka/ganesha-ashtakam.txt',
		   },
		   {
			  name: 'ganesha-kavacham',
			  display: 'గణేశ కవచం',
			  url: '/assets/stotras/vinayaka/ganesha-kavacham.txt',
		   },
		   {
			  name: 'ganesha-ashtottara-satha-namavali',
			  display: 'గణేశ అష్టోత్తర శత నామావళి',
			  url: '/assets/stotras/vinayaka/ganesha-ashtottara-satha-namavali.txt',
		   },
		   {
			  name: 'ganesha-dwadasa-nama-stotram',
			  display: 'గణేశ ద్వాదశనామ స్తోత్రం',
			  url: '/assets/stotras/vinayaka/ganesha-dwadasa-nama-stotram.txt',
		   },
		   {
			  name: 'ganesha-mangalaashtakam',
			  display: 'గణేశ మంగళాష్టకం',
			  url: '/assets/stotras/vinayaka/ganesha-mangalaashtakam.txt',
		   },
		   {
			  name: 'vathapi-gana-pathimbhaje',
			  display: 'వాతాపి గణపతిం భజేహం',
			  url: '/assets/stotras/vinayaka/vathapi-gana-pathimbhaje.txt',
		   },
		   {
			  name: 'siddhi-vinayaka-stotram',
			  display: 'సిద్ధి వినాయక స్తోత్రం',
			  url: '/assets/stotras/vinayaka/siddhi-vinayaka-stotram.txt',
		   },
		   {
			  name: 'sankata-nasana-ganesha-stotram',
			  display: 'సంకట నాశన గణేశ స్తోత్రం',
			  url: '/assets/stotras/vinayaka/sankata-nasana-ganesha-stotram.txt',
		   },
    ],
  },
  {
	   category: 'శ్రీ వేంకటేశ్వర',
	   page: 'venkateswara',
	   list: [
		  {
			 name: 'sri-venkateswara-suprabhatham',
			 display: 'శ్రీ వేంకటేశ్వర సుప్రభాతం',
			 url: '/assets/stotras/venkateswara/sri-venkateswara-suprabhatham.txt',
		  },
		  {
			 name: 'govinda-naamaavali',
			 display: 'గోవింద నామావళి',
			 url: '/assets/stotras/venkateswara/govinda-naamaavali.txt',
		  },
		  {
			 name: 'sri-venkateswara-stotram',
			 display: 'శ్రీ వేంకటేశ్వర స్తోత్రం',
			 url: '/assets/stotras/venkateswara/sri-venkateswara-stotram.txt',
		  },
		  {
			 name: 'sri-venkateswara-vajrakavacha-stotram',
			 display: 'శ్రీ వేంకటేశ్వర వజ్ర కవచ స్తోత్రం',
			 url: '/assets/stotras/venkateswara/sri-venkateswara-vajrakavacha-stotram.txt',
		  },
	   ],
  },
  {
	   category: 'శ్రీ ఆంజనేయ',
	   page: 'hanuman',
	   list: [
		  {
			 name: 'anjaneya-dhandakam',
			 display: 'ఆంజనేయ దండకం',
			 url: '/assets/stotras/hanuman/anjaneya-dhandakam.txt',
		  },
		  {
			 name: 'hanumaan-chaalisa',
			 display: 'హనుమాన్ చాలీసా',
			 url: '/assets/stotras/hanuman/hanumaan-chaalisa.txt',
		  },
		  {
			 name: 'hanuman-badabanala-stotram',
			 display: 'శ్రీ హనుమాన్ బడబానల స్తోత్రం',
			 url: '/assets/stotras/hanuman/hanuman-badabanala-stotram.txt',
		  },
		  {
			 name: 'anjaneya-sahasra-namamulu',
			 display: 'ఆంజనేయ సహస్ర నామం',
			 url: '/assets/stotras/hanuman/anjaneya-sahasra-namamulu.txt',
		  },
		  {
			 name: 'hanuma-ashtottara-satha-namavali',
			 display: 'హనుమ అష్టోత్తర శత నామావళి',
			 url: '/assets/stotras/hanuman/hanuma-ashtottara-satha-namavali.txt',
		  },
	   ],
  },
  {
	   category: 'శ్రీ కృష్ణ',
	   page: 'sri-krishna',
	   list: [
		  {
			 name: 'achyutashtakam',
			 display: 'అచ్యుతాష్టకం',
			 url: '/assets/stotras/sri-krishna/achyutashtakam.txt',
		  },
		  {
			 name: 'madhurashtakam',
			 display: 'మధురాష్టకం',
			 url: '/assets/stotras/sri-krishna/madhurashtakam.txt',
		  },
		  {
			 name: 'krishnam-kalaya-sakhi',
			 display: 'కృష్ణం కలయ సఖి',
			 url: '/assets/stotras/sri-krishna/krishnam-kalaya-sakhi.txt',
		  },
		  {
			 name: 'aalokaye-sri-balakrishnam',
			 display: 'ఆలోకయే శ్రీ బాలకృష్ణం',
			 url: '/assets/stotras/sri-krishna/aalokaye-sri-balakrishnam.txt',
		  },
		  {
			 name: 'bala-mukundashtakam',
			 display: 'బాల ముకుందాష్టకం',
			 url: '/assets/stotras/sri-krishna/bala-mukundashtakam.txt',
		  },
		  {
			 name: 'ganamurthe-srikrishna-venu',
			 display: 'గానమూర్తే శ్రీకృష్ణవేణు',
			 url: '/assets/stotras/sri-krishna/ganamurthe-srikrishna-venu.txt',
		  },
	   ],
  },
  {
	   category: 'అన్నమయ్య కీర్తనలు',
	   page: 'annamayya-keerthanalu',
	   list: [
		  {
			 name: 'antaryami-alasithi',
			 display: 'అంతర్యామి అలసితి',
			 url: '/assets/stotras/annamayya-keerthanalu/antaryami-alasithi.txt',
		  },
		  {
			 name: 'andariki-aadharamina',
			 display: 'అందరికి ఆధారమైన',
			 url: '/assets/stotras/annamayya-keerthanalu/andariki-aadharamina.txt',
		  },
		  {
			 name: 'anni-mantramulu',
			 display: 'అన్ని మంత్రములు',
			 url: '/assets/stotras/annamayya-keerthanalu/anni-mantramulu.txt',
		  },
		  {
			 name: 'alara-chanchalamina',
			 display: 'అలర చంచలమైన',
			 url: '/assets/stotras/annamayya-keerthanalu/alara-chanchalamina.txt',
		  },
		  {
			 name: 'alarulu-kuriyaga',
			 display: 'అలరులు కురియగ',
			 url: '/assets/stotras/annamayya-keerthanalu/alarulu-kuriyaga.txt',
		  },
		  {
			 name: 'indariki-abhayambu',
			 display: 'ఇందరికి అభయంబు',
			 url: '/assets/stotras/annamayya-keerthanalu/indariki-abhayambu.txt',
		  },
		  {
			 name: 'kattedura-vaikuntamu',
			 display: 'కట్టెదుర వైకుంఠము',
			 url: '/assets/stotras/annamayya-keerthanalu/kattedura-vaikuntamu.txt',
		  },
		  {
			 name: 'chakkani-thalliki',
			 display: 'చక్కని తల్లికి',
			 url: '/assets/stotras/annamayya-keerthanalu/chakkani-thalliki.txt',
		  },
		  {
			 name: 'chalada-brahmamidi',
			 display: 'చాలదా బ్రహ్మమిది',
			 url: '/assets/stotras/annamayya-keerthanalu/chalada-brahmamidi.txt',
		  },
		  {
			 name: 'chalada-harinama',
			 display: 'చాలదా హరి నామ',
			 url: '/assets/stotras/annamayya-keerthanalu/chalada-harinama.txt',
		  },
		  {
			 name: 'choodaramma-satulara',
			 display: 'చూడరమ్మ సతులారా',
			 url: '/assets/stotras/annamayya-keerthanalu/choodaramma-satulara.txt',
		  },
		  {
			 name: 'cheri-yashodaku',
			 display: 'చేరి యశోదకు',
			 url: '/assets/stotras/annamayya-keerthanalu/cheri-yashodaku.txt',
		  },
		  {
			 name: 'jagadapu-chanuvula',
			 display: 'జగడపు చనువుల',
			 url: '/assets/stotras/annamayya-keerthanalu/jagadapu-chanuvula.txt',
		  },
		  {
			 name: 'jo-achyutananda',
			 display: 'జో అచ్యుతానంద',
			 url: '/assets/stotras/annamayya-keerthanalu/jo-achyutananda.txt',
		  },
		  {
			 name: 'tandanaana-ahi',
			 display: 'తందనానా అహి',
			 url: '/assets/stotras/annamayya-keerthanalu/tandanaana-ahi.txt',
		  },
		  {
			 name: 'tiruveedhula-merasi',
			 display: 'తిరువీధుల మెఱసీ',
			 url: '/assets/stotras/annamayya-keerthanalu/tiruveedhula-merasi.txt',
		  },
		  {
			 name: 'deva-devam-bhaje',
			 display: 'దేవ దేవం భజే',
			 url: '/assets/stotras/annamayya-keerthanalu/deva-devam-bhaje.txt',
		  },
		  {
			 name: 'nanati-bathuku',
			 display: 'నానాటి బతుకు',
			 url: '/assets/stotras/annamayya-keerthanalu/nanati-bathuku.txt',
		  },
		  {
			 name: 'narayanathe-namo-namo',
			 display: 'నారాయణతే నమో నమో',
			 url: '/assets/stotras/annamayya-keerthanalu/narayanathe-namo-namo.txt',
		  },
		  {
			 name: 'bhavamulona',
			 display: 'భావము లోన',
			 url: '/assets/stotras/annamayya-keerthanalu/bhavamulona.txt',
		  },
		  {
			 name: 'bhavayami-gopalabalam',
			 display: 'భావయామి గోపాలబాలం',
			 url: '/assets/stotras/annamayya-keerthanalu/bhavayami-gopalabalam.txt',
		  },
		  {
			 name: 'manujudai-putti',
			 display: 'మనుజుడై పుట్టి',
			 url: '/assets/stotras/annamayya-keerthanalu/manujudai-putti.txt',
		  },
		  {
			 name: 'moosina-mutyalakele',
			 display: 'మూసిన ముత్యాలకేలే',
			 url: '/assets/stotras/annamayya-keerthanalu/moosina-mutyalakele.txt',
		  },
		  {
			 name: 'radha-madhava-rathi',
			 display: 'రాధా మాధవ రతి చరితమితి',
			 url: '/assets/stotras/annamayya-keerthanalu/radha-madhava-rathi.txt',
		  },
		  {
			 name: 'raama-dasaratha-raama',
			 display: 'రామా దశరథ రామా',
			 url: '/assets/stotras/annamayya-keerthanalu/raama-dasaratha-raama.txt',
		  },
		  {
			 name: 'vinaro-bhagyamu',
			 display: 'వినరో భాగ్యము',
			 url: '/assets/stotras/annamayya-keerthanalu/vinaro-bhagyamu.txt',
		  },
		  {
			 name: 'shodasa-kalanidhiki',
			 display: 'షోడశ కళానిధికి',
			 url: '/assets/stotras/annamayya-keerthanalu/shodasa-kalanidhiki.txt',
		  },
	   ],
  },
  {
	   category: 'త్యాగరాజ  కీర్తనలు',
	   page: 'tyagaraja-keerthanalu',
	   list: [
		  {
			 name: 'gandhamu-pooyaruga',
			 display: 'గంధము పూయరుగా',
			 url: '/assets/stotras/tyagaraja-keerthanalu/gandhamu-pooyaruga.txt',
		  },
		  {
			 name: 'nagumomu-ganaleni',
			 display: 'నగుమోము గనలేని',
			 url: '/assets/stotras/tyagaraja-keerthanalu/nagumomu-ganaleni.txt',
		  },
		  {
			 name: 'bantu-reethi-koluvu',
			 display: 'బంటు రీతి కొలువు',
			 url: '/assets/stotras/tyagaraja-keerthanalu/bantu-reethi-koluvu.txt',
		  },
		  {
			 name: 'marugelara-o-raghava',
			 display: 'మరుగేలరా ఓ రాఘవా',
			 url: '/assets/stotras/tyagaraja-keerthanalu/marugelara-o-raghava.txt',
		  },
		  {
			 name: 'samajavaragamana',
			 display: 'సామజ వర గమనా',
			 url: '/assets/stotras/tyagaraja-keerthanalu/samajavaragamana.txt',
		  },
		  {
			 name: 'endaro-mahanubhavulu',
			 display: 'ఎందరో మహానుభావులు',
			 url: '/assets/stotras/tyagaraja-keerthanalu/endaro-mahanubhavulu.txt',
		  },
		  {
			 name: 'kana-kana-ruchira',
			 display: 'కన కన రుచిరా',
			 url: '/assets/stotras/tyagaraja-keerthanalu/kana-kana-ruchira.txt',
		  },
		  {
			 name: 'jagadananda-karaka',
			 display: 'జగదానంద కారక',
			 url: '/assets/stotras/tyagaraja-keerthanalu/jagadananda-karaka.txt',
		  },
		  {
			 name: 'duduku-gala',
			 display: 'దుడుకు గల',
			 url: '/assets/stotras/tyagaraja-keerthanalu/duduku-gala.txt',
		  },
		  {
			 name: 'samayaniki-thagu',
			 display: 'సమయానికి తగు మాటలాడెనె',
			 url: '/assets/stotras/tyagaraja-keerthanalu/samayaniki-thagu.txt',
		  },
		  {
			 name: 'nanu-palimpa-nadachi-vachithivo',
			 display: 'నను పాలింప నడచి వచ్చితివో',
			 url: '/assets/stotras/tyagaraja-keerthanalu/nanu-palimpa-nadachi-vachithivo.txt',
		  },
	   ],
  },
  {
	   category: 'దేవి స్తోత్రములు',
	   page: 'devi-stotramulu',
	   list: [
		  {
			 name: 'katyayani-mantra',
			 display: 'కాత్యాయని మంత్రం',
			 url: '/assets/stotras/devi-stotramulu/katyayani-mantra.txt',
		  },
		  {
			 name: 'soundaryalahari',
			 display: 'సౌందర్య లహరీ',
			 url: '/assets/stotras/devi-stotramulu/soundaryalahari.txt',
		  },
		  {
			 name: 'manidweepa-varnana-1',
			 display: 'మణిద్వీప వర్ణన - 1 ',
			 url: '/assets/stotras/devi-stotramulu/manidweepa-varnana-1.txt',
		  },
		  {
			 name: 'manidweepa-varnana-2',
			 display: 'మణిద్వీప వర్ణన - 2',
			 url: '/assets/stotras/devi-stotramulu/manidweepa-varnana-2.txt',
		  },
		  {
			 name: 'manidweepa-varnana-3',
			 display: 'మణిద్వీప వర్ణన - 3 ',
			 url: '/assets/stotras/devi-stotramulu/manidweepa-varnana-3.txt',
		  },
		  {
			 name: 'lalitha-ashtottara-satanamavali',
			 display: 'లలితా అష్టోత్తర శత నామావళి',
			 url: '/assets/stotras/devi-stotramulu/lalitha-ashtottara-satanamavali.txt',
		  },
		  {
			 name: 'indrakshi-stotram',
			 display: 'ఇంద్రాక్షీ స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/indrakshi-stotram.txt',
		  },
		  {
			 name: 'sri-annapurna-ashtottara-satanamavali',
			 display: 'శ్రీ అన్నపూర్ణా అష్టోత్తర శతనామావళిః',
			 url: '/assets/stotras/devi-stotramulu/sri-annapurna-ashtottara-satanamavali.txt',
		  },
		  {
			 name: 'sri-annapurna-stotram',
			 display: 'శ్రీ అన్నపూర్ణా స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/sri-annapurna-stotram.txt',
		  },
		  {
			 name: 'bhavani-ahtakam',
			 display: 'భవానీ అష్టకం',
			 url: '/assets/stotras/devi-stotramulu/bhavani-ahtakam.txt',
		  },
		  {
			 name: 'meenakshi-pancharatna-stotram',
			 display: 'మీనాక్షీ పంచ రత్న స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/meenakshi-pancharatna-stotram.txt',
		  },
		  {
			 name: 'kanakadhara-stotram',
			 display: 'కనకధారా స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/kanakadhara-stotram.txt',
		  },
		  {
			 name: 'syamala-dandakam',
			 display: 'శ్యామలా దండకం',
			 url: '/assets/stotras/devi-stotramulu/syamala-dandakam.txt',
		  },
		  {
			 name: 'navadurga-stotram',
			 display: 'నవ దుర్గా స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/navadurga-stotram.txt',
		  },
		  {
			 name: 'durga-pancharatnam',
			 display: 'దుర్గా పంచ రత్నం',
			 url: '/assets/stotras/devi-stotramulu/durga-pancharatnam.txt',
		  },
		  {
			 name: 'sri-mangalagowri-ashtottara-satanamavali',
			 display: 'శ్రీ మంగళగౌరీ అష్టోత్తర శతనామావళిః',
			 url: '/assets/stotras/devi-stotramulu/sri-mangalagowri-ashtottara-satanamavali.txt',
		  },
		  {
			 name: 'manidweepa-varnanam',
			 display: 'మణిద్వీప వర్ణనం',
			 url: '/assets/stotras/devi-stotramulu/manidweepa-varnanam.txt',
		  },
		  {
			 name: 'sri-lalitha-trisathinamavali',
			 display: 'శ్రీ లలితా త్రిశతినామావళిః',
			 url: '/assets/stotras/devi-stotramulu/sri-lalitha-trisathinamavali.txt',
		  },
		  {
			 name: 'sri-durga-chalisa',
			 display: 'శ్రీ దుర్గా చాలీసా',
			 url: '/assets/stotras/devi-stotramulu/sri-durga-chalisa.txt',
		  },
		  {
			 name: 'sri-gayathri-sahasra-nama-stotram',
			 display: 'శ్రీ గాయత్రి సహస్ర నామ స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/sri-gayathri-sahasra-nama-stotram.txt',
		  },
		  {
			 name: 'devi-aswadhati-amba-stuti',
			 display: 'దేవీ అశ్వధాటీ (అంబా స్తుతి)',
			 url: '/assets/stotras/devi-stotramulu/devi-aswadhati-amba-stuti.txt',
		  },
		  {
			 name: 'sri-mahishasura-mardhini-stotram',
			 display: 'శ్రీ మహిషాసుర మర్దినీ స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/sri-mahishasura-mardhini-stotram.txt',
		  },
		  {
			 name: 'siddha-kunjika-stotram',
			 display: 'సిద్ధ కుంజికా స్తోత్రం',
			 url: '/assets/stotras/devi-stotramulu/siddha-kunjika-stotram.txt',
		  },
	   ],
  },
  {
	   category: 'రామదాసు కీర్తనలు',
	   page: 'ramadasu-keertanalu',
	   list: [
		  {
			 name: 'ikshwaku-kulatilaka',
			 display: 'ఇక్ష్వాకు కుల తిలకా',
			 url: '/assets/stotras/ramadasu-keertanalu/ikshwaku-kulatilaka.txt',
		  },
		  {
			 name: 'paluke-bangaramayena',
			 display: 'పలుకే బంగారమాయెనా',
			 url: '/assets/stotras/ramadasu-keertanalu/paluke-bangaramayena.txt',
		  },
		  {
			 name: 'paahi-ramaprabho',
			 display: 'పాహి రామప్రభో',
			 url: '/assets/stotras/ramadasu-keertanalu/paahi-ramaprabho.txt',
		  },
		  {
			 name: 'ae-teeruga-nanu-dayachuchedavo',
			 display: 'ఏ తీరుగ నను దయ చూచెదవో',
			 url: '/assets/stotras/ramadasu-keertanalu/ae-teeruga-nanu-dayachuchedavo.txt',
		  },
	   ],
  },
  {
	   category: 'లక్ష్మీ స్తోత్రములు',
	   page: 'lakshmi-stotramulu',
	   list: [
		  {
			 name: 'sri-mahalakshmi-ashtottara-satha-namavali',
			 display: 'శ్రీ మహా లక్ష్మీ అష్టోత్తర శత నామావళి',
			 url: '/assets/stotras/lakshmi-stotramulu/sri-mahalakshmi-ashtottara-satha-namavali.txt',
		  },
		  {
			 name: 'ashtalakshmi-stotram',
			 display: 'అష్ట లక్ష్మీ స్తోత్రం',
			 url: '/assets/stotras/lakshmi-stotramulu/ashtalakshmi-stotram.txt',
		  },
		  {
			 name: 'mahalakshmyashtakam',
			 display: 'మహా లక్ష్మ్యష్టకం',
			 url: '/assets/stotras/lakshmi-stotramulu/mahalakshmyashtakam.txt',
		  },
	   ],
  },
  {
	   category: 'శ్రీరామ & విష్ణు స్తోత్రములు',
	   page: 'rama-vishnu-stotramulu',
	   list: [
		  {
			 name: 'sri-ramachandra-krupalu',
			 display: 'శ్రీ రామచంద్ర కృపాళు',
			 url: '/assets/stotras/rama-vishnu-stotramulu/sri-ramachandra-krupalu.txt',
		  },
		  {
			 name: 'sri-vishnu-ashtottara-sathanama-stotram',
			 display: 'శ్రీ విష్ణు అష్టోత్తర శతనామ స్తోత్రం',
			 url: '/assets/stotras/rama-vishnu-stotramulu/sri-vishnu-ashtottara-sathanama-stotram.txt',
		  },
		  {
			 name: 'narayana-kavacham',
			 display: 'నారాయణ కవచం',
			 url: '/assets/stotras/rama-vishnu-stotramulu/narayana-kavacham.txt',
		  },
		  {
			 name: 'rama-lalee-meghasyama-lalee',
			 display: 'రామ లాలీ మేఘశ్యామ లాలీ',
			 url: '/assets/stotras/rama-vishnu-stotramulu/rama-lalee-meghasyama-lalee.txt',
		  },
		  {
			 name: 'narayana-stotram',
			 display: 'నారాయణ స్తోత్రం',
			 url: '/assets/stotras/rama-vishnu-stotramulu/narayana-stotram.txt',
		  },
		  {
			 name: 'sri-seetharam-stotram',
			 display: 'శ్రీ సీతారామ స్తోత్రం',
			 url: '/assets/stotras/rama-vishnu-stotramulu/sri-seetharam-stotram.txt',
		  },
		  {
			 name: 'ramayana-jaya-mantram',
			 display: 'రామాయణ జయ మంత్రం',
			 url: '/assets/stotras/rama-vishnu-stotramulu/ramayana-jaya-mantram.txt',
		  },
		  {
			 name: 'dasarathi-satakam',
			 display: 'దాశరథీ శతకం',
			 url: '/assets/stotras/rama-vishnu-stotramulu/dasarathi-satakam.txt',
		  },
	   ],
  },
  {
	   category: 'ఇతర స్తోత్రములు',
	   page: 'other-stotramulu',
	   list: [
		  {
			 name: 'anantha-padmanabha-swami-ashtottara-satha-namavali',
			 display: 'అనంత పద్మనాభ స్వామి అష్టోత్తర శత నామావళి',
			 url: '/assets/stotras/other-stotramulu/anantha-padmanabha-swami-ashtottara-satha-namavali.txt',
		  },
		  {
			 name: 'evarani-nirnayinchirira',
			 display: 'ఎవరని నిర్ణయించిరిరా',
			 url: '/assets/stotras/other-stotramulu/evarani-nirnayinchirira.txt',
		  },
		  {
			 name: 'goda-devi-ashtottara-satha-stotram',
			 display: 'గోదా దేవీ అష్టోత్తర శత స్తోత్రం',
			 url: '/assets/stotras/other-stotramulu/goda-devi-ashtottara-satha-stotram.txt',
		  },
		  {
			 name: 'navarathna-malika-stotram',
			 display: 'నవరత్న మాలికా స్తోత్రం',
			 url: '/assets/stotras/other-stotramulu/navarathna-malika-stotram.txt',
		  },
		  {
			 name: 'sri-saraswathi-ashtottara-satha-nama-stotram',
			 display: 'శ్రీ సరస్వతీ అష్టోత్తర శత నామ స్తోత్రం',
			 url: '/assets/stotras/other-stotramulu/sri-saraswathi-ashtottara-satha-nama-stotram.txt',
		  },
		  {
			 name: 'saraswathi-stotram',
			 display: 'సరస్వతీ స్తోత్రం',
			 url: '/assets/stotras/other-stotramulu/saraswathi-stotram.txt',
		  },
	   ],
  },
]
