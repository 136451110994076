/* eslint-disable max-len */

export const EMPTY_STRING = ''
export const DATE_FORMAT = 'MMM yyyy'

export const MONTHS = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

export const MONTH_NAME = { jan: 'January',
  feb: 'February',
  mar: 'March',
  apr: 'April',
  may: 'May',
  jun: 'June',
  jul: 'July',
  aug: 'August',
  sep: 'September',
  oct: 'October',
  nov: 'November',
  dec: 'December' }

export const PICTURE = [
  { file: 'S_0001.jpg', tag: '' },
  { file: 'S_0003.jpg', tag: '' },
  { file: 'S_0005.jpg', tag: '' },
  { file: 'S_0025.jpg', tag: '' },
  { file: 'S_0012.jpg', tag: '' },
  { file: 'S_0015.jpg', tag: '' },
  { file: 'S_0006.jpg', tag: '' },
  { file: 'S_0010.jpg', tag: '' },
  { file: 'S_0011.jpg', tag: '' },
  { file: 'S_0013.jpg', tag: '' },
  { file: 'S_0014.jpg', tag: '' },
  { file: 'S_0027.jpg', tag: '' },
  { file: 'S_0016.jpg', tag: '' },
  { file: 'S_0017.jpg', tag: '' },
  { file: 'S_0018.jpg', tag: '' },
  { file: 'S_0026.jpg', tag: '' },
  { file: 'S_0028.jpg', tag: '' },
  { file: 'S_0031.jpg', tag: '' },
  { file: 'S_0020.jpg', tag: '' },
  { file: 'S_0029.jpg', tag: '' },
  { file: 'S_0004.jpg', tag: '' },
  { file: 'S_0030.jpg', tag: '' },

]

export const FESTIVALS = {
  jan: { name: 'జనవరి', festivals: ['1. ఆంగ్ల సంవత్సరాది', '2. ముక్కోటి మతత్రయ ఏకదశి', '3. కూర్మ ద్వాదశి', '5. ఆర్ర్ధోత్సవం', '6. ఆర్ధ్రా దర్శనం, శివ ముక్కోటి', '10. సంకష్టహర చతుర్ధి', '11. త్యాగరాజ స్వామి ఆరాధన', '14. భోగి', '15. మకర సంక్రాంతి', '16. కనుమ', '18. మతత్రయ ఏకదశి', '20. మాస శివరాత్రి', '23. చంద్రోదయం', '24. శ్రవణం కార్తె', '26. రిపబ్లిక్ డే', '28. శ్రీ భద్రావతి భావనాఋషి కళ్యాణం, రథ సప్తమి,సూర్య చంద్ర వ్రతం', '29. భీష్మాష్టమి', '30. మధ్వనవమి'] }, dec: ['fest 21', 'fest 22', 'fest 23'] }

export const RAASULU = [{ name: 'మేష రాశి', content: 'mesha-raasi' },
  { name: 'వృషభ రాశి', content: 'vrushabha-raasi' },
  { name: 'మిథున రాశి', content: 'mithuna-raasi' },
  { name: 'కర్కాటక రాశి', content: 'karkataka-raasi' },
  { name: 'సింహ రాశి', content: 'simha-raasi' },
  { name: 'కన్య రాశి', content: 'kanya-raasi' },
  { name: 'తులా రాశి', content: 'thulaa-raasi' },
  { name: 'వృశ్చిక రాశి', content: 'vrutchika-raasi' },
  { name: 'ధను రాశి', content: 'dhanu-raasi' },
  { name: 'మకర రాశి', content: 'makara-raasi' },
  { name: 'కుంభ రాశి', content: 'kumbha-raasi' },
  { name: 'మీన రాశి', content: 'meena-raasi' },
]
