import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Home } from '../../containers'
import { PageNotFound } from '../../components'

export const SLASH_ROUTE = '/'
export const HOME_ROUTE = '/calendar'
export const HOME_MONTH_YEAR_ROUTE = '/calendar/:year/:month'
export const RAASI_ROUTE = '/raasi-phalaalu'
export const RAASI_NAME_ROUTE = '/raasi-phalaalu/:name'
export const IMAGES_ROUTE = '/images'
export const STOTRAS_ROUTE = '/stotras'
export const STOTRAS_CATEGORY_ROUTE = '/stotras/:category'
export const STOTRAS_NAME_ROUTE = '/stotras/:category/:name'

export const MainRoutes = () => (
  <Switch>
    <Route exact path={SLASH_ROUTE} render={props => <Home {...props} />} />
    <Route exact path={[HOME_ROUTE, HOME_MONTH_YEAR_ROUTE]} render={props => <Home {...props} />} />
    <Route
      exact path={[STOTRAS_ROUTE, STOTRAS_CATEGORY_ROUTE, STOTRAS_NAME_ROUTE]}
      render={props => <Home {...props} selectedKey={'stotras'} />} />
    <Route exact path={IMAGES_ROUTE} render={props => <Home {...props} selectedKey={'images'} />} />
    <Route
      exact path={[RAASI_ROUTE, RAASI_NAME_ROUTE]} render={props => (
        <Home
          {...props} selectedKey={'raasi-phalaalu'} />
      )} />
    <Route component={PageNotFound} path="*" />
  </Switch>
)
