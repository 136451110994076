import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { get } from 'lodash'
// import { HOME_ROUTE, STOTRAS_NAME_ROUTE } from '../../router'
import Home from './Home'
// import TermsNConditions from './TermsNConditions'
// import PrivacyPolicy from './PrivacyPolicy'
import styles from './style.scss'
import Stotras from './Stotras'
import Raasi from './Raasi'
import Images from './Images'

export default class MainContent extends Component {
  static propTypes = {
    filter: PropTypes.object.isRequired,
    getContent: PropTypes.func.isRequired,
    gotoURL: PropTypes.func.isRequired,
    selectedMonth: PropTypes.string,
    selectedYear: PropTypes.func.isRequired,
    showYear: PropTypes.string,
    openCalendar: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loginWithRedirect: PropTypes.func.isRequired,
    mainModel: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    selectedKey: PropTypes.string,
    stotra: PropTypes.string,
    stotraName: PropTypes.func.isRequired,
    showRaasi: PropTypes.func.isRequired,
    curRaasi: PropTypes.string,
  }

  static defaultProps = {
    selectedKey: null,
  }

  constructor(props) {
    super(props)
    this.url = window.location.href
  }

  render() {
    const { match } = this.props
    let { selectedKey } = this.props
    selectedKey = selectedKey || get(match, 'params.key', '')
    const name = get(match, 'params.name', '')
    return (
      <div className={classnames(styles.gridRow)}>
        { selectedKey === '' && <Home {...this.props} />}
        { selectedKey === 'raasi-phalaalu' && <Raasi {...this.props} />}
        { selectedKey === 'stotras' && <Stotras name={name} {...this.props} />}
        { selectedKey === 'images' && <Images {...this.props} />}

      </div>
    )
  }
}
