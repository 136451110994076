// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".footer___1zBKX{background-color:#191919}.f_menus___3205I{padding:60px 0}.f_menus___3205I h3{padding:0 0 20px 0px;color:#fff;font-size:19px;text-transform:uppercase;font-family:\"Roboto Condensed\", serif}.f_menus___3205I ul li{line-height:25px;padding-left:20px;background:url(/assets/images/dot-list.png) no-repeat 0 8px;list-style-type:none}.f_menus___3205I p,.f_menus___3205I div{cursor:pointer}.f_menus___3205I div,.f_menus___3205I a,.f_menus___3205I p{color:#999;text-decoration:none}.f_menus___3205I div:hover,.f_menus___3205I a:hover,.f_menus___3205I p:hover{color:#fff}\n", "",{"version":3,"sources":["C:/AARWII/aarwii/Git/subhagadiya-web-app/src/components/common/MainFooter/style.scss"],"names":[],"mappings":"AAAA,gBACC,wBAAyB,CACzB,iBAEA,cAAe,CADhB,oBAGE,oBAAqB,CACnB,UAAW,CACX,cAAe,CAClB,wBAAyB,CACzB,qCAAsC,CAPvC,uBAUE,gBAAiB,CACjB,iBAAkB,CAClB,2DAA4D,CAC5D,oBAAqB,CAbvB,wCAgBE,cAAe,CAhBjB,2DAmBE,UAAW,CACT,oBAAqB,CApBzB,6EAuBE,UAAW","file":"style.scss","sourcesContent":[".footer {\r\n\tbackground-color: #191919;\r\n}\r\n.f_menus {\r\n\tpadding: 60px 0;\r\n\th3 {\r\n\t\tpadding: 0 0 20px 0px;\r\n    color: #fff;\r\n    font-size: 19px;\r\n\ttext-transform: uppercase;\r\n\tfont-family: \"Roboto Condensed\", serif;\r\n\t}\r\n\tul li {\r\n\t\tline-height: 25px;\r\n\t\tpadding-left: 20px;\r\n\t\tbackground: url(/assets/images/dot-list.png) no-repeat 0 8px;\r\n\t\tlist-style-type: none;\r\n\t}\r\n\tp, div {\r\n\t\tcursor: pointer;\r\n\t}\r\n\tdiv, a, p {\r\n\t\tcolor: #999;\r\n    text-decoration: none;\r\n\t}\r\n\tdiv:hover, a:hover, p:hover{\r\n\t\tcolor: #fff;\r\n\t}\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"footer": "footer___1zBKX",
	"f_menus": "f_menus___3205I",
	"fMenus": "f_menus___3205I"
};
module.exports = exports;
