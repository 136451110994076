import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Accordion, Card } from 'react-bootstrap'
import { get, map } from 'lodash'
import cs from 'classnames'
import { STOTRAS } from '../../../constants/stotras'
import st from './style.scss'

export default class RightPanel extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
  stotraName: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
  caller: PropTypes.string.isRequired,
}
static defaultProps = {
}

setValues = (page, sub_page, name, url, caller) => {
  const { gotoURL, getContent } = this.props
  gotoURL(`/stotras/${page}/${sub_page}`)
  getContent(name, url, caller)
}

render() {
  const { caller } = this.props
  return (
    <div className={cs(st.page_sidebar)}>
      <div className={cs(st.sidebar)}>
        <div className={cs(st.stotra_panel)}>
          <ul>
            {map(STOTRAS, (stotra, key) => (
              <li>
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle as={Card.Header} className={cs(st.title)} eventKey="14">
                    <div className={cs(st.category)}>
                      {get(stotra, 'category')}
                    </div>
                  </Accordion.Toggle>
                  <div>
                    <Accordion.Collapse eventKey="14">
                      <div className={cs(st.stotra_list)}>
                        <ul>
                          {map(get(stotra, 'list'), (name, index) => (
                            <li className={cs(st.link)}>
                              <div onClick={() => this.setValues(get(stotra, 'page'), get(name, 'name'),
                                get(name, 'display'), get(name, 'url'), caller)}>
                                {get(name, 'display')}
                              </div>
                            </li>
                          )) }
                        </ul>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </li>
            ))		}
          </ul>
        </div>
      </div>
      <div />
    </div>
  )
}
}
