import React from 'react'
import { render } from 'react-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/style.scss'
import { AppContainer } from './containers'
import { stores } from './store'

render(
  <div>
    <AppContainer {...stores} />
  </div>,
  document.getElementById('root'),
)

window.store = stores
