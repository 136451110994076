/* eslint-disable max-len */
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import cs from 'classnames'
import { map, get } from 'lodash'
import PropTypes from 'prop-types'
import RightPanel from '../../common/RightPanel'
import { RAASULU } from '../../../constants'
import st from './style.scss'

export default class Raasi extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
  stotraName: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
  showRaasi: PropTypes.func.isRequired,
  curRaasi: PropTypes.string,
}

render() {
  const { gotoURL, curRaasi, showRaasi } = this.props
  const url = `/assets/raasi/${curRaasi}.jpg`
  return (
    <Fragment>
      <section className={cs(st.main_content)}>
        <Container fluid>
          <Row>
            <Col lg={3} md={3} xl={3}>
              <div className={cs(st.title)}>
                <h2>రాశి ఫలాలు <span> 2023</span></h2>
                <div className={cs(st.raasulu)}>
                  { map(RAASULU, (raasi, key) => (
                    <div className={cs(st.raasi)} onClick={() => showRaasi(get(raasi, 'content'))}>
                      {get(raasi, 'name')}
                    </div>
                  ))}
                </div>
              </div>
            </Col>
            <Col className={cs(st.left_Content)} lg={6} md={6} xl={6}>
              <div className={cs(st.raasi_img)}>
                <img alt={curRaasi} src={url} />
              </div>
            </Col>
            <Col lg={2} md={2} xl={2}>
              <div className={cs(st.stotram)}>
                <h2>స్తోత్రములు</h2>
                <RightPanel caller={'raasi'} getContent={this.props.getContent} gotoURL={gotoURL} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}
}
