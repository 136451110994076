/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { withRouter } from 'react-router-dom'
import { Dropdown, Container, Row, Col } from 'react-bootstrap'
import { get } from 'lodash'
import { MONTH_NAME } from '../../../constants'
import { Icon, IconType } from '..'
import st from './style.scss'

class MainHeader extends Component {
  static propTypes = {
    gotoURL: PropTypes.func.isRequired,
    openURL: PropTypes.func.isRequired,
    openCalendar: PropTypes.func.isRequired,
    selectedYear: PropTypes.func.isRequired,
    changeMonth: PropTypes.func.isRequired,
    showMonth: PropTypes.string,
    sideBarMini: PropTypes.bool,
    toggleSideBar: PropTypes.func,
    showYear: PropTypes.string,
  }

  static defaultProps = {
    sideBarMini: false,
    toggleSideBar: _ => _,
  }
  constructor(props){
    super(props)
    this.state = {
      toggle: true,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

downloadCalendar = () => {
  const { showMonth, showYear } = this.props
  fetch(`/assets/pdf/${showMonth}-${showYear}.pdf`).then(response => {
    response.blob().then(blob => {
      const fileURL = window.URL.createObjectURL(blob)
      const alink = document.createElement('a')
      alink.href = fileURL
      alink.download = `Subhagadiya-${showMonth}-${showYear}.pdf`
      alink.click()
    })
  })
}

showMenu =() => {
  const { CLOSE, HAMBURGER } = IconType.COMMON
  const { toggle } = this.state
  if (toggle){
    return (
      <div className={cs(st.mobile_menu)} onClick={() => this.toggleMenuState()}>
        <Icon
          color={'#3bb4c1'}
          height={'48'}
          names={[HAMBURGER]}
          viewHeight={'512'}
          viewWidth={'512'} width={'48'} />
      </div>
    )
  }
  return (

    <div className={cs(st.mobile_links)}>
      <div className={cs(st.mobile_menu_close)} onClick={() => this.toggleMenuState()}>
        <Icon
          color={'#3bb4c1'}
          height={'24'}
          names={[CLOSE]}
          viewHeight={'24'}
          viewWidth={'24'} width={'24'} />
      </div>
      <ul>
        <li><div className={cs(st.link)} onClick={() => this.props.gotoURL('/calendar')}>క్యాలెండర్</div></li>
        <li><div className={cs(st.link)} onClick={() => this.props.gotoURL('/raasi-phalaalu')}>
          రాశి ఫలాలు</div></li>
        <li><div className={cs(st.link)} onClick={() => this.props.gotoURL('/stotras')}>స్తోత్రములు</div></li>
        <li><div className={cs(st.link)} onClick={() => this.props.openURL('/images')}>చిత్రములు</div></li>
      </ul>
    </div>
  )
}

toggleMenuState= () => {
  const { toggle } = this.state
  this.setState({
    toggle: !toggle,
  })
}
  renderLogo = () => {
    const { showMonth, showYear } = this.props

    // const { toggle } = this.state
    return (
      <Fragment>
        <Container fluid>

          <div className={cs(st.header)}>
            <div className={cs(st.logo)} onClick={() => this.props.gotoURL('/')}>
              <img alt="calendar" src="/assets/images/logo.png" />
              <h1 className={cs(st.logo_font)}> శుభ ఘడియ తెలుగు క్యాలెండర్ </h1>
            </div>
            {this.showMenu(true)}
            <div className={cs(st.links)}>
              <ul>
                <li><div className={cs(st.link)} onClick={() => this.props.gotoURL('/calendar')}>క్యాలెండర్</div></li>
                <li><div className={cs(st.link)} onClick={() => this.props.gotoURL('/raasi-phalaalu')}>
                  రాశి ఫలాలు</div></li>
                <li><div className={cs(st.link)} onClick={() => this.props.gotoURL('/stotras')}>స్తోత్రములు</div></li>
                <li><div className={cs(st.link)} onClick={() => this.props.openURL('/images')}>చిత్రములు</div></li>
              </ul>
            </div>
          </div>
        </Container>

        <div className={cs(st.menubar)}>
          <Container fluid>
            <Row>
              <Col lg={3} md={3} xl={3} />
              <Col lg={6} md={6} sm={6} xl={6}>
                <div className={cs(st.month_download)}>
                  <div className={cs(st.month_bar)}>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/jan`)}>Jan</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/feb`)}>Feb</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/mar`)}>Mar</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/apr`)}>Apr</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/may`)}>May</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/jun`)}>Jun</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/jul`)}>Jul</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/aug`)}>Aug</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/sep`)}>Sep</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/oct`)}>Oct</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/nov`)}>Nov</div>
                    <div className={cs(st.month)} onClick={() => this.props.gotoURL(`/calendar/${showYear}/dec`)}>Dec</div>
                  </div>
                  <div className={cs(st.month_mini)}>
                    <Dropdown className={cs(st.dropdown)} onSelect={this.props.changeMonth}>
                      <Dropdown.Toggle id="dropdown-basic" variant="success">
                        {get(MONTH_NAME, showMonth)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="jan">January</Dropdown.Item>
                        <Dropdown.Item eventKey="feb">Febrauary</Dropdown.Item>
                        <Dropdown.Item eventKey="mar">March</Dropdown.Item>
                        <Dropdown.Item eventKey="apr">April</Dropdown.Item>
                        <Dropdown.Item eventKey="may">May</Dropdown.Item>
                        <Dropdown.Item eventKey="jun">June</Dropdown.Item>
                        <Dropdown.Item eventKey="jul">July</Dropdown.Item>
                        <Dropdown.Item eventKey="aug">August</Dropdown.Item>
                        <Dropdown.Item eventKey="sep">September</Dropdown.Item>
                        <Dropdown.Item eventKey="oct">October</Dropdown.Item>
                        <Dropdown.Item eventKey="nov">November</Dropdown.Item>
                        <Dropdown.Item eventKey="dec">December</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className={cs(st.year_download)}>
                    <Dropdown className={cs(st.dropdown)} onSelect={this.props.selectedYear}>
                      <Dropdown.Toggle id="dropdown-basic" variant="success">
                        {showYear}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="2024">2024</Dropdown.Item>
                        <Dropdown.Item eventKey="2023">2023</Dropdown.Item>
                        <Dropdown.Item eventKey="2022">2022</Dropdown.Item>
                        <Dropdown.Item eventKey="2021">2021</Dropdown.Item>
                        <Dropdown.Item eventKey="2020">2020</Dropdown.Item>
                        <Dropdown.Item eventKey="2019">2019</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className={cs(st.download)} onClick={this.downloadCalendar}>Download PDF</div>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={3} xl={3} />
            </Row>
          </Container>
        </div>
      </Fragment>
    )
  }
  render = () => (
    <Fragment>
      {this.renderLogo()}
    </Fragment>
  )
}

MainHeader.propTypes = {
  alignLeft: PropTypes.bool.isRequired,
  applicationModel: PropTypes.object.isRequired,
  history: PropTypes.object,
  node: PropTypes.node,
  onSelect: PropTypes.func,
  selectedTab: PropTypes.number,
  showLogout: PropTypes.bool,
  tabBody: PropTypes.array.isRequired,
}

MainHeader.defaultProps = {
  node: null,
  history: {},
  selectedTab: 0,
  onSelect: () => {},
  showLogout: true,
}
export default withRouter(MainHeader)
