/* eslint-disable max-len */
import React, { Component } from 'react'
import cs from 'classnames'
import { Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { map, get } from 'lodash'
import RightPanel from '../../common/RightPanel'
import st from './style.scss'
import { PICTURE } from '../../../constants/constants'

export default class Images extends Component {
    static propTypes = {
      gotoURL: PropTypes.func.isRequired,
      getContent: PropTypes.func.isRequired,
    }
    render() {
      const { gotoURL } = this.props
      return (
        <div>
          <Container>
            <Row className={cs(st.images)}>
              <Col lg={8} md={8} xl={8}>
                <Row>
                  {map(PICTURE, (pic, key) => (
                    <Col lg={4} md={4} xl={4}>
                      <div className={cs(st.card)}>
                        <img alt="subhagadiya" src={`/assets/images/gods/${get(pic, 'file')}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4} md={6} xl={4}>
                <div className={cs(st.stotram)}>
                  <h2>స్తోత్రములు</h2>
                  <RightPanel caller={'images'} getContent={this.props.getContent} gotoURL={gotoURL} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    }
}
