import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cs from 'classnames'
import { Container, Col, Row } from 'react-bootstrap'
import st from './style.scss'

export default class MainFooter extends Component {
static propTypes = {
  gotoURL: PropTypes.func.isRequired,
}
render() {
  return (
    <div>
      <section className={cs(st.footer)}>
        <Container>
          <Row className={cs(st.f_menus)}>
            <Col lg={4} md={4} xl={4}>
              <h3> Quick Menu</h3>
              <ul>
                <li>
                  <div onClick={() => this.props.gotoURL('/calendar')}>క్యాలెండర్</div>
                </li>
                <li>
                  <div onClick={() => this.props.gotoURL('/raasi-phalaalu')}>రాశి ఫలాలు</div>
                </li>
                <li>
                  <div onClick={() => this.props.gotoURL('/stotras')}>స్తోత్రములు</div>
                </li>
                <li>
                  <div onClick={() => this.props.gotoURL('/images')}>ప్రముఖ చిత్రములు</div>
                </li>
              </ul>
            </Col>
            <Col lg={4} md={4} xl={4}>
              <h3> Social Media</h3>
              <ul>
                <li>
                  <a href="https://www.facebook.com/subhagadiya" target="_blank">Facebook</a>
                </li>
                <li>
                  <a href="https://www.youtube.com/subhagadiya" target="_blank">YouTube</a>
                </li>
              </ul>
            </Col>
            <Col lg={4} md={4} xl={4}>
              <ul>
                <li>
                  <p>© 2019 - 2024 Subhagadiya.com</p>
                  <p>All rights reserved.</p>
                </li>
              </ul>
            </Col>
          </Row>

        </Container>
      </section>
    </div>
  )
}
}
